<template>
  <items-list :component="EventSingle" :items="events" list-page-slug="events">
    <template #title>
      {{ data.title }}
    </template>

    <template #button>
      <app-button
        v-if="data.button"
        :page="data.button.page.data"
        :url="data.button.url"
      >
        {{ data.button.text }}
      </app-button>
    </template>

    <template #pagination>
      <app-pagination
        v-if="pagination && showPagination"
        :page="pagination.page"
        :pages-count="pagination.pageCount"
        path="events"
      />
    </template>
  </items-list>
</template>

<script lang="ts" setup>
import { useErrorTracking } from "~/modules/error/composables/useErrorTracking";
import EventSingle from "~/modules/events/components/EventSingle.vue";
import type { EventsDto } from "~/modules/events/ts/dto/EventsDto";

import type { Event } from "~/modules/events/ts/Event";

import AppButton from "~/modules/shared/components/AppButton.vue";
import AppPagination from "~/modules/shared/components/AppPagination.vue";
import ItemsList from "~/modules/shared/components/ItemsList/ItemsList.vue";
import { CLIENT_ERROR_SLUGS } from "~/modules/shared/constants/ClientErrorSlugs";
import type { EventsList } from "~/modules/shared/ts/pageComponents/EventsList";
import type { Pagination } from "~/modules/shared/ts/Pagination";
import type { StrapiLocale } from "~/node_modules/@nuxtjs/strapi/dist/runtime/types";
import type { Ref } from "~/node_modules/vue/dist/vue";

const props = defineProps<{
  data: EventsList;
}>();

const { locale } = useI18n();
const { find } = useStrapi4();
const { trackError } = useErrorTracking();
const pagination: Ref<Pagination | undefined> = ref();
const events: Ref<Event[]> = ref([]);
const route = useRoute();

const showPagination = computed(() => (pagination.value?.pageCount || 0) > 1);

try {
  if (props.data.events.data.length) {
    events.value = props.data.events.data;
  } else {
    const { data, error } = await useAsyncData("events", () =>
      find<EventsDto>("events", {
        fields: ["event_date", "event_location", "title", "slug"],
        locale: locale.value as StrapiLocale,
        pagination: {
          pageSize: props.data.per_page,
          page: pagination.value?.page || Number(route.params.page) || 1,
        },
        populate: ["image"],
        sort: ["publishedAt"],
      }),
    );

    if (error.value) throw error;

    pagination.value = data.value?.meta.pagination;
    events.value = data.value?.data || [];
  }
} catch (err) {
  trackError(CLIENT_ERROR_SLUGS.EVENTS_LIST_INIT, err);
}
</script>
