<template>
  <item-single :theme="theme" :url="eventUrl">
    <template #image>
      <img
        v-if="data.attributes.image.data"
        :alt="data.attributes.image.data.attributes.alternativeText"
        :src="useImageUrl(data.attributes.image, 'small')"
        class="image"
        loading="lazy"
      />
    </template>

    <template #caption>
      <p v-if="data.attributes.event_date" class="caption__item">
        <icon-clock class="item__icon" />

        <span>
          {{ useDate(data.attributes.event_date) }}
        </span>
      </p>

      <p v-if="data.attributes.event_location" class="caption__item">
        <icon-map-pin class="item__icon" />

        <span>
          {{ data.attributes.event_location }}
        </span>
      </p>
    </template>

    <template #title>
      {{ data.attributes.title }}
    </template>

    <template #link-text> See Details</template>
  </item-single>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { Event } from "~/modules/events/ts/Event";

import IconClock from "~/modules/shared/components/icons/IconClock.vue";
import IconMapPin from "~/modules/shared/components/icons/IconMapPin.vue";
import ItemSingle from "~/modules/shared/components/Item/ItemSingle.vue";
import { useDate } from "~/modules/shared/composables/useDate";
import { useImageUrl } from "~/modules/shared/composables/useImageUrl";

const props = withDefaults(
  defineProps<{
    data: Event;
    listPageSlug: string | undefined;
    theme?: "tile" | "row";
  }>(),
  {
    theme: "row",
  },
);

const eventUrl = computed(
  () => `${props.listPageSlug || ""}/${props.data.attributes.slug}`,
);
</script>

<style lang="scss" scoped>
.image {
  @apply w-full;
}

.caption__item {
  @apply flex items-center mb-4 md:mb-0 md:mr-7 text-body-xxs text-primary-400;

  .item__icon {
    @apply mr-3;
  }
}
</style>
